const imgCalifornien = require('/assets/img/massages/massage_suedois_californien.jpg'),
    imgBolKansu = require('/assets/img/massages/massage_bol_kansu.jpg'),
    imgIndien = require('/assets/img/massages/massage_indien.jpg'),
    imgShiro = require('/assets/img/massages/massage_shiroshampi.jpg'),
    imgThai = require('/assets/img/massages/massage_thai.jpg'),
    imgAyurvedique = require('/assets/img/massages/massage_ayurvedique.jpg'),
    imgPerso = require('/assets/img/massages/massage_personnalise.jpg'),
    imgKobido = require('/assets/img/massages/massage_kobido.jpg')

const AUTOPLAY = false

var massages = [
    {
        title: 'MASSAGE SUÉDOIS CALIFORNIEN',
        subtitle: "Massage à l'huile",
        img: `<img
                    src="${imgCalifornien}"
                    alt="Massage suédois californien"
                    class="massage-illustration"
                />`,
        littleDesc: 'Enveloppant et profondément relaxant, alliant douceur et fermeté',
        desc: `<p class="first-paraph">
            Ce massage, à l’huile, est un équilibre harmonieux entre détente musculaire
            en profondeur et relaxation en douceur.
        </p>
        <p>Deux techniques qui se complètent.</p>
        <p>
            Le massage suédois, entrainant et dynamique, alterne des pressions fortes et
            légères, des rythmes lents et rapides.
        </p>
        <p>
            Sa fonction principale est de redonner un souffle d’énergie au corps, de
            décontracter les muscles et de soulager les douleurs musculaires et
            articulaires. On pourrait le qualifier aussi de sectoriel dans le sens que
            l’on s’attarde sur des zones précises qui en ont le plus besoin.
        </p>
        <p>
            Le massage californien est plus doux et enveloppant. Les mouvements sont
            plus fluides et englobants, sans discontinuité afin de ne pas brusquer le
            corps. Cette relaxation en douceur donne l’impression d’être sculpté, que le
            corps et l’esprit sont en harmonie, que l’on "habite" vraiment son corps.
        </p>
        <p>Quelques bienfaits du massage suédois californien :</p>
        <ul>
            <li>Détente neuro-musculaire</li>
            <li>Meilleure circulation sanguine et lymphatique</li>
            <li>Meilleure digestion</li>
            <li>Elimination des toxines</li>
            <li>Amélioration du sommeil, du système immunitaire</li>
            <li>Amélioration de l’estime de soi ...</li>
        </ul>
        <p>Ce massage peut durer une demi-heure, 45 minutes, une heure ou une heure trente.</p>
        <p>Idéalement, un massage complet (du corps entier) dure une heure.</p>`,
    },
    {
        title: 'MASSAGE DES PIEDS AU BOL KANSU',
        subtitle: '',
        img: `<img
                    src="${imgBolKansu}"
                    alt="Massage des pieds au bol kansu"
                    class="massage-illustration"
                />`,
        littleDesc: 'Ancrage, équilibre, profonde détente',
        desc: `<p>Le massage des pieds au bol Kansu est un rituel ayurvédique profondément relaxant et équilibrant pour l’ensemble du corps et de l’esprit.</p>

        <p>Originellement, dans l’ouest de l’Inde, ce massage est un moment d’échange privilégié entre les très jeunes enfants (dès l’âge de 4 ans) et leurs parents ou grands-parents.</p>
        
        <p>L’enfant fait un massage avec le petit bol kansu (s’il en a envie, jamais forcé) tout en écoutant les histoires de la mythologie indienne qui transmettent les plus belles valeurs de l’existence.</p>
        
        <p>Cette technique de massage a été intégrée dans les mesures d’hygiène indiennes et serait à l’origine de leur proverbe préféré « la maladie n’atteint pas celui qui se masse les pieds avant de dormir, tout comme les serpents n’approchent pas les aigles ».</p>
        
         
        
        <p>Le bol kansu ou kansa vati se traduit par « récipient en kansa ».</p>
        
        <p>Le kansa est le nom de l’alliage de 5 métaux dont les principaux sont le cuivre, le zinc et le bronze.</p>
        
        <ul>
            <li>Le cuivre absorbe l’excès de chaleur produit par le corps</li>
        
            <li>Le zinc redonne un élan d’énergie et stimule les tissus musculaires</li>
        
            <li>Le bronze joue le rôle de catalyseur</li>
        </ul>
        
        <p>Le bol kansu va entrer en résonance avec le corps et agir en fonction de ses besoins, notamment en équilibrant l’élément feu. S’il y a excès de feu, caractérisé par de la colère, du stress, de l’irritabilité ou de la nervosité, le massage aidera à calmer, apaiser.</p>
        
        <p>Si, au contraire, il y a carence de feu, qui peut se manifester par de la fatigue, de la paresse voire de la dépression, alors le massage apportera la vitalité et l’énergie manquante.</p>
        
        <p>En agissant sur les points réflexes des pieds, le bol kansu active la bonne circulation de l’énergie vitale dans tout l’organisme.</p>
        
        <p>Il restaure le calme, fait taire le mental, rétablit le sommeil tout en évacuant la colère, le stress, l’angoisse et la nervosité. Il apporte une profonde détente, soulage les jambes lourdes, améliore la santé des yeux.</p>
        
        <p>Ce massage est effectué avec de l’huile légèrement chaude sur les pieds et le bas des jambes (un pantalon souple avec bas évasé est la tenue idéale). Pratiqué d’abord manuellement puis avec un bol kansu, c’est une véritable réflexologie plantaire à l’indienne qui s’effectue en douceur et en profondeur.</p>
 
        <p style="text-align: center;"><span style="font-style:italic">Ancrage, équilibre, profonde détente</span></p>
        
        <p style="text-align: center;"><span style="font-style:italic">Un bain de sensations douces et apaisantes</span></p>`,
    },
    {
        title: 'MASSAGE FACIAL JAPONAIS',
        subtitle: 'Kobido',
        img: `<img
                    src="${imgKobido}"
                    alt="Massage des pieds au bol kansu"
                    class="massage-illustration"
                />`,
        littleDesc: "Lifting manuel et naturel du visage qui relaxe en profondeur l'ensemble du corps",
        desc: `<p style="text-align: center;"><b>Kobido</b></p>
        <p>Le KOBIDO se traduit par « voie ancestrale de la beauté » (KO : ancien, BI : beauté, DO : voie).</p>
        <p>Pour la petite histoire …</p>
        <p>En 1472, au sud du Mont Fuji, les deux meilleurs maîtres du Anma, ancêtre du shiatsu, se sont affrontés pendant plusieurs mois pour trouver un soin du visage particulier qui pourrait ravir l’impératrice japonaise. Mais aucun des deux n’a gagné ; ils ont donc décidé de s’associer pour créer la maison KOBIDO.</p>
        <p>A l’origine, ce massage facial était réservé aux impératrices et aux geishas.</p>
        <p>Puis il devint, au fil du temps, un « art traditionnel de rajeunissement des femmes japonaises ».</p>
        <p>Ce massage facial est reconnu pour stimuler l’ensemble de la circulation énergétique du visage, du cou, de l’ensemble du corps et libère les tensions musculaires qui favorisent l’apparition des rides.</p>
        <p>Antiride ancestral, le KOBIDO est aujourd’hui une des techniques les plus efficaces. Sa pratique régulière permet de prolonger ou de retrouver la jeunesse du visage en assouplissant et en tonifiant les muscles, en purifiant la peau et en lui rendant son éclat.</p>
        <p>La technique de massage allie le pétrissage de la peau, le drainage et la stimulation par digito-pression des méridiens énergétiques issue du shiatsu. Entre autres, les méridiens de la vessie et du gros intestin, pour stimuler l’élimination, donc purifier et éclaircir le teint, celui de l’estomac pour une meilleure assimilation des nutriments de la peau, le triple réchauffeur pour favoriser la circulation de l’énergie.</p>
        <p>Une séance de kobido, au cours de laquelle le visage est nettoyé, hydraté et énergisé, est un véritable lifting manuel et naturel :</p>
        <ul>
            <li>Il lutte contre le relâchement cutané</li>
            <li>Il diminue les effets du stress</li>
            <li>Il redonne son éclat à la peau, augmente son élasticité, stimule la microcirculation</li>
            <li>C’est une fenêtre ouverte sur le lâcher prise et le dépaysement, une véritable bouffée d’oxygène pour le corps et l’esprit</li>
        </ul> 
        <p>C’est un soin complet, qui en plus d’avoir une efficacité sur la peau, va également apporter détente et relaxation. </p>
        <p>Ce massage convient à toutes les peaux, de la plus jeune à la plus mature, tant pour les hommes que pour les femmes. Cependant, le KOBIDO est déconseillé aux personnes ayant une peau couperosée, une inflammation de la peau, des blessures ou des écorchures.</p>
        <p>Ce massage est réalisé sur table, habillé (en tenue souple de préférence), les épaules découvertes.</p>
        <p>Une séance dure idéalement 1h15. Les bienfaits se font ressentir dès la 1ère séance. Pour un résultat optimal, il est conseillé d'effectuer une cure de 4 à 6 séances rapprochées (à raison d'une séance par semaine) puis 1 à 2 fois par mois selon le besoin.</p>
        <p style="text-align: center;"><span style="font-style:italic">Laissez-vous surprendre,</span></p>
        <p style="text-align: center;"><span style="font-style:italic">le temps d'un voyage ...</span></p>`,
    },
    {
        title: 'MASSAGE AYURVEDIQUE',
        subtitle: '(Abhyanga)',
        img: `<img
                    src="${imgAyurvedique}"
                    alt="Massage ayurvedique"
                    class="massage-illustration"
                />`,
        littleDesc: 'Massage apaisant, tonifiant et drainant. Pour retrouver un tempérament clair et calme',
        desc: `<p>Le massage ayurvédique appelé également abhyanga, est une composante à part entière de l’AYURVEDA, médecine traditionnelle et ancestrale indienne, au même titre que le yoga, la phytothérapie, l’aromathérapie ou la diététique ayurvédique.</p>
        <p>Du sanscrit AYUR (la vie) et VEDA (la science ou la connaissance), le terme ayurvéda signifie donc « connaissance de la vie ou de la longévité ».</p>
        <p>En Inde, le massage ayurvédique est considéré comme une thérapie corporelle essentielle à l’hygiène de vie quotidienne ; après une naissance, le massage quotidien du bébé et de sa maman pendant une quarantaine de jours est courant en soin post-natal.</p>
        <p>Dès l’âge de 6 ans, les enfants, par tradition, sont encouragés à masser les autres membres de la famille. </p> <p> Le massage ayurvédique fait maintenant partie des soins corporels recherchés en France et à l’étranger.</p>
        <p>Au-delà de ses bienfaits physiques, cet acte d’attention va, à travers le toucher, nourrir le corps, les sens et l’âme à tous les âges de la vie.</p>
        <p>Le massage ayurvédique est avant tout un massage énergétique à la fois dynamisant et apaisant </p> <p>La liste de ses bénéfices est longue, en voici quelques-uns :</p>
        <ul>
            <li>Une relaxation profonde, une détente physique et mentale, l’évacuation des toxines et l’hydratation de la peau.</li>
            <li>Cette technique indienne améliore la circulation des fluides corporels, la digestion, le fonctionnement du système nerveux</li>
            <li>Elle assouplit les articulations et tonifie les tissus musculaires</li>
            <li>Elle favorise la régénération et la vitalité</li>
            <li>Enfin, elle aide à l’ancrage</li>
        </ul>
        <p>A qui ce massage est destiné ?</p>
        <p>A tout le monde !</p>
        <p>Il est surtout recommandé aux personnes souffrant de stress, de fatigue et ayant du mal à gérer leurs émotions.</p>
        <p>A tous ceux qui souhaitent retrouver un tempérament clair et calme.</p>
        <p>Ce massage dure 1H30 et se fait avec des huiles légèrement chaudes sur la table de massage.</p>
        <p>La personne est recouverte d’un drap de bain et d’une polaire pour ne pas se refroidir puis chaque partie du corps est découverte, « travaillée » puis recouverte ; aucune partie du corps n’est oubliée, des orteils au cuir chevelu.</p>
        <p>A la fois dynamique et enveloppant, le massage ayurvédique se caractérise par une alternance de manœuvres douces et longues puis rapides et rythmées ; il allie effleurages, frictions, digitopression, pétrissage et vibration.</p>
        <p style="text-align: center;">
            "<span style="font-style:italic">Il n’y a rien de plus fort au monde que la douceur.</span>" Han Suyin
        </p>`,
    },
    {
        title: 'RÉFLEXOLOGIE PLANTAIRE THAÏ',
        subtitle: '(Thaï des pieds)',
        img: `<img
                    src="${imgThai}"
                    alt="Massage thai des pieds"
                    class="massage-illustration"
                />`,
        littleDesc: 'Relaxation profonde, stimulation de la circulation sanguine et énergétique',
        desc: `<p style="text-align: center;"><b>(Thaï des pieds)</b></p>  

        <p class="first-paraph">La réflexologie plantaire thaï, appelée également Thaï des pieds ou Thaï foot, est une technique ancestrale
        et traditionnelle thaïlandaise qui allie avec efficacité et originalité réflexologie plantaire, massage et étirements.</p>
        
        <p>Le protocole, pratiqué en Chine depuis plus de trois mille ans, est un enchaînement d’une
        soixantaine de mouvements spécifiques :</p>
        <ul>
            <li>Un ensemble de pétrissage, lissage, pressions profondes sur lignes et points d’énergie, effectués
        des orteils jusqu’aux genoux.</li>
            <li>Une stimulation des zones réflexes du pied avec un petit bâtonnet en bois qui permet d’exercer des
        pressions précises.</li>
        </ul>
        
        <p style="text-decoration: underline;">Ses principaux bienfaits</p>

        <ul>
            <li>Relaxation profonde, voire surprenante</li>
            <li>Amélioration de la circulation énergétique</li>
            <li>Elimination des toxines</li>
            <li>Diminution des migraines, des troubles du sommeil, des troubles digestifs</li>
            <li>Amélioration de la circulation sanguine</li>
            <li>Diminution des tensions musculaires et nerveuses</li>
            <li>Réduction du stress, de la fatigue</li>
        </ul>

        <p>La séance de réflexologie plantaire thaï, réalisée avec un mélange d’huiles, dure une heure.</p>
        <p>Il est préférable d’avoir une tenue souple, permettant l’accès aux genoux.</p>
        
        <p style="text-align: center; font-style:italic; margin-top: 3rem;">
            Pour une harmonie, une légèreté d’être
        </p>`,
    },
    {
        title: 'MASSAGE SHIROSHAMPI',
        subtitle: 'Haut du dos, épaules, nuque, crâne et visage',
        img: `<img
                    src="${imgShiro}"
                    alt="Massage shiroshampi"
                    class="massage-illustration"
                />`,
        littleDesc: "Massage permettant de lâcher prise, de clarifier le mental et tonifier l'énergie",
        desc: `<p class="first-paraph">Originaire de l’Inde, le Shiroshampi est un massage ancestral transmis de mère en fille.</p>
        <p>Il consiste à masser la tête, les épaules et le cou car ce sont à ces endroits précis que s’accumulent
        toutes nos tensions quotidiennes.</p>
        <p>Ce massage traditionnel, shiro signifiant tête et shampi signifiant massage, est à l’origine du mot
        shampooing.</p>
        <p>Faisant partie de la médecine ayurvédique, ce massage profond utilise l’acupressure et différentes
        techniques de massage pour stimuler des points d’énergie vitale et aide le corps à se rééquilibrer.</p>
        <p>Le Shiroshampi (massage à l’huile) est plus complet que le massage crânien dos.</p>
        <p>Essentiellement relaxant et dynamisant, il procure bien d’autres bienfaits.</p>
        <p>En voici quelques-uns :</p>
        <ul>
            <li>Clarification du mental</li>
            <li>Lâcher prise</li>
            <li>Tonification de l’énergie</li>
            <li>Action sur les maux de tête, les migraines</li>
            <li>Augmentation de la concentration et de la vigilance (notamment en période d’examens)</li>
            <li>Stimulation du cuir chevelu</li>
            <li>Relâchement musculaire</li>
            <li>Dégagement des sinus</li>
        </ul>
        <p>Ce massage dure 45 minutes et se reçoit assis sur une chaise.</p>
        <p>Je propose un paréo ou une fouta pour les femmes, les hommes sont torse nu.</p>
        
        <p style="text-align: center; font-style:italic">
            Un moment de profonde détente et de lâcher prise
        </p>
        <p style="text-align: center; font-style:italic">
            Fraîcheur, Respiration
        </p>
        <p style="text-align: center; font-style:italic">
            ...
        </p>`,
    },
    {
        title: 'AU FIL DE SOI',
        subtitle: 'Massage personnalisé',
        img: `<img
                    src="${imgPerso}"
                    alt="Massage personnalisé"
                    class="massage-illustration"
                />`,
        littleDesc:
            'Pensé, imaginé ensemble,</br> à l’inspiration des différentes techniques,</br> à l’intuition du moment',
        desc: `<p class="first-paraph" style="text-align: center; font-style: italic">
            … Ou un temps de massage composé, écrit ensemble</p>
        <p>
            Rebooster, calmer une colère, un état de stress, redonner de l’énergie,
            détendre profondément, calmer le mental, détendre les muscles, équilibrer
            l’énergie, drainer, retrouver les idées claires, une meilleure
            concentration, diminuer la cellulite, retrouver un bon sommeil, une harmonie
            …
        </p>
        <p>
            Un massage que l’on pense ensemble pour répondre le mieux possible à vos
            besoins, vos attentes du moment (prévoir un quart d’heure d’échange avant le
            massage).
        </p>
        <p>Un exemple de massage composé :</p>
        <ul style="list-style-type: none;">
            <li>
                Massage à l’huile du dos, façon suédois californien, pour détendre les
                muscles
            </li>
            <li>
                Un massage du crâne et du visage, pour calmer le mental, retrouver les idées
                claires et une meilleure concentration
            </li>
            <li>Puis un passage du petit bol kansu pour équilibrer l’énergie</li>
            <li>Ou une détente sonore avec un carillon koshi</li>
            <li>Ou une, deux, trois propositions d’automassages…</li>
        </ul>
        <p>
            Une combinaison simple ou composée pour votre état, votre humeur, votre
            envie du jour.
        </p>
        <p>
            Le temps de votre massage s’adaptera à votre souhait ainsi qu’à votre
            budget.
        </p>
        <p style="text-align: center; font-style: italic; margin-top: 3rem;">
            Au fil de soi, au fil des mains
        </p>`,
    },
]

var i = 0
var started = false
var paused = false
var timeout = null

//Differents components
var $window = $(window),
    pauseButton = $('.massages-left i.far'),
    prevButton = $('.massages-changer .fa-angle-left'),
    nextButton = $('.massages-changer .fa-angle-right'),
    infosButton = $('.massage-infos'),
    progressBar = $('.progress')

function viewport() {
    var e = window,
        a = 'inner'
    if (!('innerWidth' in window)) {
        a = 'client'
        e = document.documentElement || document.body
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] }
}

//Assign events when page loaded
$(function () {
    if (viewport().width <= 992) {
        paused = true
    }

    prevButton.on('click', function () {
        prevMassage()
        if (!paused) {
            toggleState()
        }
    })

    nextButton.on('click', function () {
        nextMassage()
        if (!paused) {
            toggleState()
        }
    })

    pauseButton.on('click', toggleState)

    infosButton.on('click', pauseAnim)

    $window.on('resize', function () {
        if ($window.width() <= 992) {
            paused = true
            progressBar.stop()
        }
    })
})

//Go to next massage
function nextMassage() {
    i = i >= massages.length - 1 ? 0 : i + 1
    changeContent(massages[i].title, massages[i].subtitle, massages[i].littleDesc, massages[i].img)

    //Relaunch animation
    if (!paused) {
        progressBar.animate({ width: '0%' }, 1500)

        timeout = setTimeout(() => {
            animateProgressBar()
        }, 2000)
    }
}

//Go to previous massage
function prevMassage() {
    i = i <= 0 ? massages.length - 1 : i - 1
    changeContent(massages[i].title, massages[i].subtitle, massages[i].littleDesc, massages[i].img)
}

//Change content
function changeContent(title, subtitle, desc, img) {
    $('.massage-title')
        .fadeTo(400, 0, function () {
            $('.massage-title').text(title)
        })
        .fadeTo(400, 1)

    $('.massage-subtitle')
        .fadeTo(400, 0, function () {
            $('.massage-subtitle').text(subtitle)
        })
        .fadeTo(400, 1)

    $('.massage-description')
        .fadeTo(400, 0, function () {
            $('.massage-description').html(desc)
        })
        .fadeTo(400, 1)

    $('.massage-infos').fadeTo(400, 0).fadeTo(400, 1)

    $('.illustration-container')
        .fadeTo(400, 0, function () {
            $('.illustration-container').html(img)
        })
        .fadeTo(400, 1)
}

//Animate progress bar
function animateProgressBar() {
    if (!paused) {
        progressBar.animate(
            { width: '100%' },
            {
                duration: 5000,
                done: nextMassage,
            }
        )
    }
}

//Toggle play state
function toggleState() {
    if (!paused) {
        pauseAnim()
    } else {
        playAnim()
    }
}
//Pause anim
function pauseAnim() {
    progressBar.stop()
    clearTimeout(timeout)
    progressBar.animate({ width: '0%' }, 500)
    pauseButton.removeClass('fa-pause-circle').addClass('fa-play-circle')
    paused = true
}
//Play anim
function playAnim() {
    paused = false
    animateProgressBar()
    pauseButton.removeClass('fa-play-circle').addClass('fa-pause-circle')
}

/* ========== Reveal on scroll ========== */

var win_height_padded = $window.height() * 1.1

$window.on('scroll', function () {
    if (!started) {
        if (AUTOPLAY) {
            revealOnScroll()
        } else {
            pauseButton.removeClass('fa-pause-circle').addClass('fa-play-circle')
            paused = true
        }
    }
})

function revealOnScroll() {
    var scrolled = $window.scrollTop()
    progressBar.each(function () {
        var $this = $(this),
            offsetTop = $this.offset().top

        if (scrolled + win_height_padded > offsetTop) {
            started = true

            setTimeout(() => {
                animateProgressBar()
            }, 1000)
        }
    })
}

/* ====================================== */
/* ==========       Modal      ========== */
/* ====================================== */

//Change title
function changeModal(title, desc, img) {
    let modalMassageTitle = $('.modal_massage_title')
    let modalMassageDesc = $('.modal_massage_desc')
    let modalMassageImg = $('.modal_massage_img')

    //Change title
    modalMassageTitle.text(title)

    //Change Description
    modalMassageDesc.html(desc)

    //Change image
    modalMassageImg.html(img)
}

$(document).ready(function () {
    $('.massage-infos').on('click', () => {
        changeModal(massages[i].title, massages[i].desc, massages[i].img)

        $('body').addClass('modal__oppened')
    })

    $('.close__modal').on('click', () => {
        $('body').removeClass('modal__oppened')
    })
})
